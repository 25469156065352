import {Component, OnDestroy, OnInit} from '@angular/core';
import {fadeInAnimation} from '../_animations/fade-in.animation';
import {tada} from 'ng-animate';
import {transition, trigger, useAnimation} from '@angular/animations';
import {TranslationService} from '../_services/translation.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.sass'],
  animations: [
    fadeInAnimation,
    trigger('tada', [transition('* => animate', useAnimation(tada, {}))])
  ]
})
export class WelcomeComponent implements OnInit, OnDestroy {
  public letter = [];
  public language: string;
  private languageSubscription;
  constructor(public translate: TranslationService) {}
  ngOnInit(): void {
    this.languageSubscription = this.translate.getCurrentLanguage().subscribe(x => this.language = x);
  }
  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }
}
