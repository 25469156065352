import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import Typed from 'typed.js';
import {TranslationService} from '../_services/translation.service';
import {fadeInAnimation} from '../_animations/fade-in.animation';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.sass'],
  animations: [fadeInAnimation],
})
export class AboutMeComponent implements OnInit, OnDestroy {
  @HostBinding('@fadeInAnimation') get fadeInAnimation() {return; }
  imageLoader = true;
  private languageSubscription;
  private typed: Typed;
  private englishMessage = ['Hello! ^200', 'Hello, my name\'s ' +
  '<span class="text-color-method">Joaquin Cespedes</span> and I\'m a ' +
  '<span class="text-color-method">Software Architect and Engineer</span> from Chile.^600 <br><br>' +
  'I\'m passionate about building ' +
  '<strong class="text-color-declaration">high quality software</strong> ' +
  'with intuitive and dynamic user experiences that improves the lives of those around me. ^600' +
  'I enjoy each aspect from start to finish because I love what I do, developing software, web sites and ' +
  'web applications for people all over the world.^600 <br><br>' +
  'I was born in Uruguay and moved on to live in Chile at a very young ' +
  'age so i\'m a <span class="text-color-method">Native Spanish speaker</span> but ' +
  'thanks to our Digital era and my curiosity I was able to develop <span class="text-color-method">Fluent English</span> ' +
  'by playing video games like World of Warcraft with english speakers, by seeing movies, ' +
  'reading books and learning programming languages since I was 14 years old. ' +
  'Nowadays it\'s a common practice for me and i keep improving everyday.'];
  private spanishMessage = ['Hola! ^200', 'Hola, mi nombre es ' +
  '<span class="text-color-method">Joaquín Céspedes</span> y ' +
  'soy un <span class="text-color-method">Arquitecto e Ingeniero de Software</span> de Chile.' +
  ' ^600 <br><br>' + 'Me apasiona construir ' +
  '<strong class="text-color-declaration">software de alta calidad</strong> ' +
  'con experiencias de usuario intuitivas y dinámicas que mejoran la vida de quienes me rodean. ^600' +
  'Disfruto cada aspecto de principio a fin porque amo lo que hago desarrollar software, sitios web y ' +
  'aplicaciones web para clientes de todo el mundo.^600 <br><br>' +
  'Nací en Uruguay y me mudé a vivir a Chile desde muy pequeño ' +
  'por lo que soy un <span class="text-color-method">hablante nativo del Español</span> pero ' +
  'gracias a nuestra era digital y mi curiosidad pude desarrollar un <span class="text-color-method">Ingles fluido</span> ' +
  'jugando videojuegos como World of Warcraft con hablantes de inglés, viendo películas, ' +
  'leyendo libros y aprendiendo lenguajes de programación desde que tenía 14 años. ' +
  'Hoy en día es una práctica común para mí y sigo mejorando día a día.'];
  constructor(private translate: TranslationService) {}
  ngOnInit() {
    this.languageSubscription = this.translate.getCurrentLanguage().subscribe(x => {
      if (this.typed) { this.typed.stop(); this.typed.destroy(); }
      if (x === 'en') {
        this.typedMessage(this.englishMessage);
      }
      if (x === 'es') {
        this.typedMessage(this.spanishMessage);
      }
    });
  }
  ngOnDestroy(): void {
    if (this.typed) { this.typed.destroy(); }
    if (this.languageSubscription) { this.languageSubscription.unsubscribe(); }
  }
  public typedMessage(msg) {
    this.typed = new Typed('#welcome', {
      strings: msg,
      // time before typing starts
      startDelay: 200,
      typeSpeed: 10,
      backDelay: 0,
      smartBackspace: true,
      backSpeed: 100,
      showCursor: true,
      loop: false
    });
  }
}
