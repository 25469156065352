import {Component, OnInit} from '@angular/core';
import '../../../src/TagCanvas.js';
import {fadeInAnimation} from '../_animations/fade-in.animation';
declare var TagCanvas: any;
@Component({
  selector: 'app-skills-sphere',
  templateUrl: './skills-sphere.component.html',
  styleUrls: ['./skills-sphere.component.sass'],
  animations: [fadeInAnimation],
})
export class SkillsSphereComponent implements OnInit {
  public skillsAsList: boolean;
  constructor() { }
  ngOnInit() {
    this.animateSkills('vcylinder', [0.05, 0]);
  }
  public listSkills() {
    this.skillsAsList = true;
  }
  public animateSkills(shapeAttr, initialSpeed) {
    this.skillsAsList = false;
    try {
      TagCanvas.Start('myCanvas', '', {
        textColour: 'rgba(255,152,0,1)',
        textFont: 'Roboto, Arial',
        shadow: 'rgba(255,152,0,.8)',
        shadowBlur : 10,
        outlineMethod: 'none',
        shadowOffset: [1, 1],
        initial: initialSpeed,
        noSelect: true,
        noMouse: false,
        reverse: true,
        depth: 0.8,
        maxSpeed: 0.05,
        shape: shapeAttr,
        zoomMin: 1,
        zoomMax: 1.4,
        noTagsMessage: false,
        shuffleTags: true
      });
    } catch (e) {
      console.log(e);
      // something went wrong, hide the canvas container
      // document.getElementById('myCanvasContainer').style.display = 'none';
    }
  }


}
