import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends TranslateService {
  private currentLanguageSubject: BehaviorSubject<string>;
  private currentLanguage: Observable<string>;
  initialize() {
    this.addLangs(['es', 'en']);
    this.setDefaultLang('en');
    this.currentLanguageSubject = new BehaviorSubject<string> (this.getStoredLanguage());
    this.currentLanguage = this.currentLanguageSubject.asObservable();
  }
  changeLanguage(language): void {
    if (this.getLangs().includes(language)) {
      this.use(language);
      localStorage.setItem('language', language);
      this.currentLanguageSubject.next(language);
    }
  }
  getCurrentLanguage(): Observable<string> {
    if (this.currentLanguageSubject) {
      return this.currentLanguage;
    } else {
      return null;
    }
  }
  getStoredLanguage(): string {
    let language = localStorage.getItem('language');
    if (language && this.getLangs().includes(language)) {
      this.use(language);
    } else {
      localStorage.setItem('language', 'en');
      this.use('en');
      language = 'en';
    }
    return language;
  }
}
