import {Component, HostBinding, OnInit} from '@angular/core';
import {fadeInAnimation} from '../_animations/fade-in.animation';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.sass'],
  animations: [fadeInAnimation],
})
export class ContactMeComponent implements OnInit {
  @HostBinding('@fadeInAnimation') get fadeInAnimation() {return; }
  constructor() { }
  ngOnInit() {
  }
}
