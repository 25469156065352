import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WelcomeComponent} from './welcome/welcome.component';
import {SkillsComponent} from './skills/skills.component';
import {AboutMeComponent} from './about-me/about-me.component';
import {MyWorkComponent} from './my-work/my-work.component';
import {ContactMeComponent} from './contact-me/contact-me.component';


const routes: Routes = [
  {path: '', component: WelcomeComponent},
  {path: 'skills', component: SkillsComponent},
  {path: 'contact', component: ContactMeComponent},
  {path: 'work', component: MyWorkComponent},
  {path: 'about', component: AboutMeComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
