import { trigger, state, animate, transition, style } from '@angular/animations';

export const fadeInAnimation =
  trigger('fadeInAnimation', [
    state('in', style({opacity: 1})),
    // route 'enter' transition
    transition(':enter', [
      // styles at start of transition
      style({ opacity: 0 }),
      // animation and styles at end of transition
      animate('.5s', style({ opacity: 1 }))
    ]),
    // transition(':leave',
    //   animate(600, style({opacity: 0}))
    // )
  ]);
