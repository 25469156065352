import {Component, OnInit} from '@angular/core';
import {TranslationService} from './_services/translation.service';
import {ToastrService} from 'ngx-toastr';
import {CookieService} from './_services/cookie.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'portfolio';
  private cookieServiceSubscription;
  constructor(
    private translate: TranslationService,
    private toastr: ToastrService,
    private cookies: CookieService
  ) {}
  ngOnInit(): void {
    this.translate.initialize();
    this.cookies.initialize();
    this.cookieServiceSubscription = this.cookies.getCookieAuthorization().subscribe(
      x => {
        if ( x === 'false' ) {
          this.toastr.info('This website uses cookies in order to track your language.', 'Cookie Consent',
            {
              closeButton: true,
              disableTimeOut: true,
              tapToDismiss: true,
              enableHtml: true,
              positionClass: 'toast-bottom-right'
            }).onHidden
            .pipe(take(1))
            .subscribe(() => {
              this.cookies.changeCookieAuthorization(true);
              console.log('click');
            });
        }
      }
    );
  }
}
