import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {fadeInAnimation} from '../_animations/fade-in.animation';
import {TranslationService} from '../_services/translation.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.sass'],
  animations: [fadeInAnimation],
})
export class SkillsComponent implements OnInit, OnDestroy {
  private languageSubscription;
  public lang: string;
  @HostBinding('@fadeInAnimation') get fadeInAnimation() { return; }
  constructor(private translate: TranslationService) {}
  ngOnInit() {
    this.languageSubscription = this.translate.getCurrentLanguage().subscribe(x => {
      this.lang = x;
    });
  }
  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }
}
